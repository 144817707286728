const urls = () => {
    return {
        // baseApiUrl:"http://localhost:8080",
        baseApiUrl: "https://bakujobs.az",
//public urls
        getCategories: "/api/public/categories/",
        getVacancies: "/api/public/vacancies/",
        filterVacancies: "/api/public/vacancies/",
        getVacancy: "/api/public/vacancy/:id",
        getCompany: "/api/public/company/:slug",
        getCompanyLogo: "/api/public/company/logo/:logoName",
        checkEmailIsAvailable: "/api/public/user/checkEmailIsAvailable",
        registerNewUser: "/api/public/user/register",
        login: "/api/public/user/login",
        logout: "/api/public/user/logout",
        verifyEmail: "/api/public/user/verify",
        forgetPass: "/api/public/user/forgetPass",
        getVacancyActionData: "/api/public/vacancy/actionData/:id",
//public blogs
        getBlog: "/api/public/blog/:slug",
        addBlog: "/api/public/blog/addBlog/:secCode",
        removeBlog: "/api/public/blog/removeBlog/:id/:secCode",
        allBlogPosts: "/api/public/blog/allBlogPosts/5",

//private urls
        getUserByToken: "/api/private/getInfo",
        addToFavorite: "/api/private/addFav/",
        removeFromFavorite: "/api/private/remFav/",
        getFavorites: "/api/private/favorites/",
        setNewslettersCategories: "/api/private/subscriptions/",
        uploadCV: "/api/private/uploadCV/",
        sendCVEmail: "/api/private/sendCVEmail/",

//admin urls
        amIAdmin: "/api/admin/isAdmin/",
        getAdminVacancy: "/api/admin/vacancy/:id",
        delAdminVacancy: "/api/admin/vacancy/:id",
        postAdminVacancy: "/api/admin/vacancy",
        getAdminCategories: "/api/admin/categories",
        postAdminLogos: "/api/admin/uploadLogo",
        postAdminHtml: "/api/admin/uploadHtml/{cvOrderId}",
        postBlogImage: "/api/admin/uploadBlogImage",

//cvApp Urls

      //getUrls
        cvAppGetAllOrders: "/api/private/cvapp/orders",
        cvAppGetCvById: "/api/private/cvapp/orders/{CvOrderId}",
        cvAppGetAvatar: "/api/private/cvapp/avatar/{fileName}",
        cvAppGetAllProducts: "/api/private/cvapp/products/{productType}",
        cvAppGetProductPreview: "/api/private/cvapp/products/preview/{productId}",
        cvAppSetProductToCvOrder: "/api/private/cvapp/orders/{CvOrderId}/setProduct/{productId}",
        cvAppGeneratePreviewOfCv: "/api/private/cvapp/orders/{CvOrderId}/genPreview",
        cvAppGetPreviewOfCv: "/api/private/cvapp/orders/{CvOrderId}/previewImage/{page}",
        cvAppChangeMargin: "/api/private/cvapp/orders/{CvOrderId}/{section}/{marginSide}",
        cvAppConfirmMargins: "/api/private/cvapp/orders/{CvOrderId}/confirmMargins",
        cvAppGetPdfFileOfCv: "/api/private/cvapp/orders/{CvOrderId}/pdfFile",
        cvAppGetUserBalance: "/api/private/cvapp/userBalance",
        cvAppGetPaymentValidity: "/api/private/cvapp/paymentValidation/{CvOrderId}",
        cvAppGetPaymentInitiate: "/api/private/cvapp/initiate/{CvOrderId}/{ipAddress}",
        cvAppGetPaymentDone: "/api/payment/paymentDone/{CvOrderId}/{txId}",

      //postUrls
        cvAppPostNewCvOrder: "/api/private/cvapp/orders",
        cvAppPostAvatarImage: "/api/private/cvapp/orders/{CvOrderId}/avatar",
        cvAppPostCvData: "/api/private/cvapp/orders/{CvOrderId}/cvdata/{comeForUpdate}",

      //delete
        cvAppDeleteCvOrder: "/api/private/cvapp/orders/{CvOrderId}",
        cvAppDeleteAvatarImage: "/api/private/cvapp/orders/{CvOrderId}/avatar",


    }
}
export default urls();
