import React, {useEffect, useState} from 'react';
import {Box, Button, Card, Chip, Divider, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import CvAppCvOrderCard from "../../components/cvAppCvOrderCard";
import {useDispatch, useSelector} from "react-redux";
import CvOrderEdit from "./cvOrderEdit";
import {AutoAwesome, CheckCircle, HelpOutline} from "@mui/icons-material";
import CvTemplates from "./cvAppTemplate";
import apiUrls from "../../apiUrls";
import axios from "axios";
import CvAppPreview from "./cvAppPreview";
import {useLocation, useNavigate} from "react-router-dom";
import CVLanguageSelection from "../../components/cvApp/cVLanguageSelection";
import CsChip from "../../miniComponents/CsChip";
import CvAppFAQ from "../../components/cvApp/cvAppFAQ";
import * as staticVars from "../../utils/staticVars";
import CvAppMainCreateNewCV from "../../components/cvApp/cvAppMainCreateNewCV";

const isMobile = window.screen.width <= 700


const CvAppMain = ({wordsWithLang}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const cvAppConfig = useSelector(state => state.cvAppConfig)
    const [cvOrders, setCvOrders] = useState([]);
    const [balance, setBalance] = useState({
        id:0 ,
        userId:0,
        balance:0 ,
        isEditable:false ,
        expireDate:null,
    });

    const [balanceString, setBalanceString] = useState("");

    const dispatcher = useDispatch();

    const getUserCvOrdersAndBalance = () => {
        navigate({pathname:'/cv',hash: ""})
        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetAllOrders, {withCredentials: true}).then(resp => {
            setCvOrders(resp.data.sort((a, b) => b.id - a.id));

            // setCvOrders([{id:"1122"}])
        })

        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetUserBalance, {withCredentials: true}).then(resp => {
            setBalance(resp.data)
            if(!resp.data.expireDate){
                setBalanceString(wordsWithLang.cvApp.balance+": "+resp.data.balance+" CV")
            }
            else {
                setBalanceString(wordsWithLang.cvApp.balance+": "+resp.data.balance+" CV | "+ wordsWithLang.cvApp.balanceExpiry+": "+resp.data.expireDate)
            }
            // setCvOrders([{id:"1122"}])
        }).catch((err)=>{
            setBalanceString(wordsWithLang.cvApp.balance+": "+balance.balance+" CV | "+ wordsWithLang.cvApp.balanceExpiry+": "+balance.expireDate)
        })
    }

    const [openTooltip, setOpenTooltip] = React.useState(false);

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };


    useEffect(getUserCvOrdersAndBalance, [])


    return (
        <div>
            <Grid container justifyContent={"center"} style={{backgroundColor: "#faf8ff", borderRadius: 15}}>
                <Grid item xs={12} justifyContent={"center"}>
                    <Typography align={"center"} className={"violetColor fade-in"} style={{
                        marginLeft: 15,
                        marginRight: 15,
                        fontWeight: 600,
                        fontSize: isMobile ? ".9" : "1.3rem",
                        marginBottom: "20px",
                        marginTop: 30
                    }}>
                        {wordsWithLang.cvApp.mainPageTitle}</Typography>
                    <Grid item xs={12}>
                        <Divider variant="middle" size="large"
                                 style={{margin: 20, marginTop: 20, marginBottom:10, fontSize: 15, border: ".5px solid rgb(212 212 212)"}}/>
                    </Grid>
                        <Box align={"left"}
                             display={"flex"}
                        style={{
                            alignItems:"center",
                            justifyContent: "flex-start",
                            color: "gray",
                            marginLeft: 30,
                            // marginRight: 10,
                            fontStyle: "italic",
                            // textDecoration: "underline",
                            fontWeight: 400,
                            fontSize: "1.5rem",
                            marginBottom: 15,
                            cursor:"pointer",
                            // marginTop: 10
                        }}>

                            <Tooltip
                                arrow
                                followCursor
                                disableFocusListener
                                open={openTooltip}
                                onClose={handleTooltipClose}
                                title={
                                    <Typography fontSize={"small"} variant={"caption"}>
                                        {wordsWithLang.cvApp.balanceInfo}
                                    </Typography>
                                }
                            >
                                <IconButton
                                    onClick={handleTooltipOpen} // Open on click
                                    onTouchStart={handleTooltipOpen} // Open on touch
                                    onMouseLeave={handleTooltipClose} // Close on mouse leave
                                >
                                    <HelpOutline color="warning" fontSize={"18"} />
                                </IconButton>
                            </Tooltip>

                        <Typography style={{textDecoration: "underline"}}>{balanceString}</Typography>
                        </Box>

                </Grid>

                {cvAppConfig.cvAppScreen === "main" && (
                    <>
                        <CvAppMainCreateNewCV
                            updateCvOrder={getUserCvOrdersAndBalance}
                            wordsWithLang={wordsWithLang}
                        />
                        <CvAppMainCVOrders
                            cvOrders={cvOrders}
                            updateCvOrder={getUserCvOrdersAndBalance}
                            wordsWithLang={wordsWithLang}
                        />
                    </>
                )}

                {cvAppConfig.cvAppScreen === "edit" && (
                    <CvOrderEdit wordsWithLang={wordsWithLang} />
                )}

                {cvAppConfig.cvAppScreen === "template" && (
                    <CvTemplates wordsWithLang={wordsWithLang} />
                )}

                {cvAppConfig.cvAppScreen === "preview" && (
                    <CvAppPreview
                        wordsWithLang={wordsWithLang}
                        userBalance={balance}
                        updateUserBalance={getUserCvOrdersAndBalance}
                    />
                )}

            </Grid>

        </div>
    );
};


// old cvorders component
const CvAppMainCVOrders = ({cvOrders, updateCvOrder, wordsWithLang}) => {
    return (
        <Grid container spacing={"2"} justifyContent={"flex-start"}>
            <Typography variant={"caption"} className={"violetColor"} fontSize={22} align={"left"} display={"block"}
                        style={{
                            width: "80%",
                            fontWeight: "600",
                            marginBottom: 30,
                            marginLeft: 50
                        }}>{wordsWithLang.cvApp.oldCVs}</Typography>
            <br/>
            {cvOrders.length === 0 ? (
                <Typography variant={"caption"} color={"darkgray"} fontSize={16} align={"left"} display={"block"}
                            style={{
                                width: "80%",
                                fontWeight: "600",
                                marginBottom: 30,
                                marginLeft: 50
                            }}>{wordsWithLang.cvApp.noOldCVs}</Typography>
            ) : cvOrders.map((CvOrder) => (
                <Grid item key={CvOrder.id} xs={12} sm={12} md={6} lg={6} xl={4}>
                <CvAppCvOrderCard key={CvOrder.id} CvOrder={CvOrder} updateCvOrders={updateCvOrder}/>
                </Grid>
            ))}
        </Grid>)
}

// create new cv  component


export default CvAppMain;
