import React, {useEffect, useState} from 'react';
import {Box, Button, Checkbox, Divider, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import {Add, ArrowDownward, CheckBox, Clear, LinkSharp} from "@mui/icons-material";
import {useDispatch} from "react-redux";

const PersonalAccordion = ({newCvOrder,
                               wordsWithLang,
                               cvAppConfigCvData,
                               updateCvDataInServer,
                               expanded,
                               setExpanded, personalInfoValidate, startCheckingPersonal,
                               errorMessagesForPersonalData}) => {

    const [personalData, setPersonalData] = useState(cvAppConfigCvData.personal)
    const [link, setLink] = useState('')
    const dispatcher = useDispatch();
    const [showAddress, setShowAddress] = useState(false)


    useEffect(() => {
        personalInfoValidate(false,personalData)
    }, [personalData, startCheckingPersonal])

    useEffect(()=>{
        if(personalData.address && personalData.address!==""){
            setShowAddress(true)
        }
    },[])


    return (
        <div>
            <TextField id={"jobTitle"}

                       // disabled={newCvOrder.status==="DRAFT2"}

                       value={personalData.jobTitle} onChange={(e) => {
                setPersonalData({...personalData, jobTitle: e.target.value})
            }} helperText={errorMessagesForPersonalData.jobTitle}
                       error={errorMessagesForPersonalData.jobTitle !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.jobTitle} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>

            <TextField id={"name"}
                       // disabled={newCvOrder.status==="DRAFT2"}
                       value={personalData.name} onChange={(e) => {
                setPersonalData({...personalData, name: e.target.value})
            }} helperText={errorMessagesForPersonalData.name}
                       error={errorMessagesForPersonalData.name !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.name} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>

            <TextField id={"surname"}
                       // disabled={newCvOrder.status==="DRAFT2"}
                       value={personalData.surname} onChange={(e) => {
                setPersonalData({...personalData, surname: e.target.value})
            }} helperText={errorMessagesForPersonalData.surname}
                       error={errorMessagesForPersonalData.surname !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.surname} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>

            <TextField id={"birthDate"}
                       // disabled={newCvOrder.status==="DRAFT2"}
                       value={personalData.birthDate} onChange={(e) => {
                setPersonalData({...personalData, birthDate: e.target.value})
            }} helperText={errorMessagesForPersonalData.birthDate}
                       error={errorMessagesForPersonalData.birthDate !== ""} type={"date"} fullWidth
                       margin="normal" label={wordsWithLang.cvApp.cvAppForms.birthDate} variant="outlined"
                       InputProps={{sx: {borderRadius: 5},inputProps: {max:  new Date().toISOString().split('T')[0]}}} InputLabelProps={{shrink: true}}/>
            <TextField id={"email"}
                       // disabled={newCvOrder.status==="DRAFT2"}
                       value={personalData.email} onChange={(e) => {
                setPersonalData({...personalData, email: e.target.value})
            }} helperText={errorMessagesForPersonalData.email}
                       error={errorMessagesForPersonalData.email !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.email} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}}}/>
            <label htmlFor="phone" style={{display: 'block', marginBottom: '10px', fontSize: '16px'}}>
                {wordsWithLang.cvApp.cvAppForms.phone}
            </label>
            <PhoneInput value={personalData.phone}
                       // disabled={newCvOrder.status==="DRAFT2"}
                       onChange={(phone) => {
                setPersonalData({...personalData, phone: phone})
            }} fullWidth margin="normal"
                        country={'az'}
                        enableAreaCodes={true}
                        specialLabel={wordsWithLang.cvApp.cvAppForms.phone}
                        variant="outlined"
                        inputProps={{
                            id: "phone",
                            name: wordsWithLang.cvApp.cvAppForms.phone,
                            required: true,
                            label: wordsWithLang.cvApp.cvAppForms.phone,
                            sx: {borderRadius: 15},
                            // helperText: errorMessagesForPersonalData.phone,
                            error: errorMessagesForPersonalData.phone !== ""
                        }
                        }
                        inputStyle={{
                            padding: '25px',
                            fontSize: '20px',
                            borderRadius: 15,
                            borderBottomLeftRadius: 0,
                            borderTopLeftRadius: 0,
                            width: "95%",
                            right: "-5%",
                            borderColor: errorMessagesForPersonalData.phone !== "" ? "#e50914" : null,
                        }}
            />



            <FormControlLabel  control={
                <Checkbox
                       // disabled={newCvOrder.status==="DRAFT2"}
                       size={"medium"} id={"addressBox"} onClick={(e) => {
                    setShowAddress(!showAddress);
                    console.log(showAddress)
                    setPersonalData({...personalData, address: ""})
                }}  checked={showAddress}  label={wordsWithLang.cvApp.cvAppForms.address}  />
            }  label={ <Typography fontWeight={500}>{wordsWithLang.cvApp.cvAppForms.address}</Typography>} style={{marginTop:"1rem"}} sx={{fontWeight:"600"}}/>

            <TextField id={"address"} focused={showAddress} style={{ display: showAddress ? "block" : "none"}}
                       onChange={(e) => {
                setPersonalData({...personalData, address: e.target.value})
            }}         disabled={showAddress === false}
                       value={personalData.address}
                       helperText={personalData.address!== null ?personalData.address.length + "/100 " + errorMessagesForPersonalData.about:null}
                       error={errorMessagesForPersonalData.about !== ""} fullWidth margin="normal"
                        variant="outlined" multiline minRows={2}
                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 100}}}/>
       <Divider style={{margin:"1rem 0"}}/>
            <TextField id={"about"}
                       // disabled={newCvOrder.status==="DRAFT2"}
                       onChange={(e) => {
                setPersonalData({...personalData, about: e.target.value})
            }}
                       value={personalData.about}
                       helperText={personalData.about!== null ?personalData.about.length + "/250 " + errorMessagesForPersonalData.about:null}
                       error={errorMessagesForPersonalData.about !== ""} fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.about} variant="outlined" multiline minRows={4}
                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 250}}}/>


            {cvAppConfigCvData.personal.links.length === 0 ?
                <Box display={"block"} alignItems="center" marginY={2}>
                    <Box alignItems={"center"} display={"flex"} style={{
                        width: "100%",
                        fontWeight: "400",
                        color: "gray",
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}>
                        <LinkSharp fontSize="small"
                                   style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noLinkAdded}
                    </Box>
                </Box>
                :
                (cvAppConfigCvData.personal.links.map((value,key) => (
                    <Box display={"block"} alignItems="center" marginY={2} key={key}>
                        <Box alignItems={"center"} display={"flex"} style={{
                            width: "100%",
                            fontWeight: "400",
                            color: "gray",
                            fontSize: "12px",
                            fontStyle: "italic"
                        }}>
                            <LinkSharp fontSize="small"
                                       style={{marginRight: 5}}/>
                            {value}
                        </Box>
                    </Box>
                )))}
            <TextField
                       // disabled={newCvOrder.status==="DRAFT2"}
                       fullWidth id={"link"}  InputProps={{sx: {borderRadius: 5}}} margin="normal"
                       value={link}
                       onChange={(e) => {
                           setLink(e.target.value)
                       }}
                       label={wordsWithLang.cvApp.cvAppForms.link} variant="outlined"
                       style={{marginLeft: 8}}/>
            <br/>
            <br/>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={6}>

                    <Button className={"addButton cleanButton"} size="small" style={{marginLeft: 8}}
                            startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}
                            onClick={() => {
                                setPersonalData({...personalData, links: []})
                                cvAppConfigCvData.personal.links = []
                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                            }}>
                        {wordsWithLang.cvApp.cvAppForms.clean}
                    </Button>

                    <Button className={link === "" ? "addButton disabledButton" : "addButton"}
                            size="small" style={{marginLeft: 8}}
                            startIcon={<Add fontSize={"medium"} style={{color: "white"}}/>}
                            onClick={() => {
                                if (link !== "") {
                                    let temp  = personalData
                                    temp.links = [...personalData.links, link]
                                    cvAppConfigCvData.personal = temp
                                    dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                    setPersonalData(temp)
                                    setLink("")
                                }
                            }}>
                        {wordsWithLang.cvApp.cvAppForms.addLink}
                    </Button>



                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Button
                        variant="contained"
                        startIcon={<ArrowDownward fontSize="medium" style={{color: 'white'}}/>}
                        className="addButton continueButton"
                        size="small"
                        style={{width: "100%", float: "right"}}
                        onClick={() => {
                            if (personalInfoValidate(true,personalData)) {
                                // dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                cvAppConfigCvData.personal = personalData
                                updateCvDataInServer("personal",cvAppConfigCvData)
                                setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                            }
                            else{
                                dispatcher({
                                    type: "OPEN_SNACK",
                                    payload: {
                                        "isSnackOpen": true,
                                        snackType: "error",
                                        snackMessage: wordsWithLang.cvApp.cvAppForms.cvAppFormErrors.checkInfos
                                    }
                                })
                            }
                        }
                        }
                    >
                        {wordsWithLang.cvApp.cvAppForms.continue}
                    </Button>
                </Grid>
            </Grid>


            <br/>
            <br/>

        </div>
    );
};

export default PersonalAccordion;
