import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import apiUrls from "../../apiUrls";
import {Button, Divider, Grid, Typography} from "@mui/material";
import CvAppFAQ from "./cvAppFAQ";
import CVLanguageSelection from "./cVLanguageSelection";
import {AutoAwesome} from "@mui/icons-material";
import React from "react";

const CvAppMainCreateNewCV = ({updateCvOrder, wordsWithLang}) => {
    const dispatcher = useDispatch();
    const newCvOrder = useSelector(state => state.cvAppConfig.cvOrder)
    const userConfig = useSelector(state => state.userConfig)
    const navigate = useNavigate()

    const createNewCv = () => {
        if (!userConfig.isLogin) {
            dispatcher({type: "OPEN_LOGIN_DIALOG", payload: true})
        } else {
            if (newCvOrder.language === null) {
                newCvOrder.language = "az"
            }
            axios.post(apiUrls.baseApiUrl + apiUrls.cvAppPostNewCvOrder, newCvOrder,
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                    updateCvOrder()
                    dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "edit"})
                    navigate({pathname:'/cv',hash: "edit"})

                }
            )
        }
    }

    const selectLanguage = (lang) => {
        let temp = newCvOrder
        if(lang===null){
            temp.language = "az"
        }
        else{
            temp.language = lang
        }
        dispatcher({type: "CV_INSTANCE_UPDATE", payload: temp})

    };



    return (
        // <CVLanguageSelection wordsWithLang={wordsWithLang} selectLanguage={selectLanguage} isMobile={isMobile} newCvOrder={newCvOrder} createNewCv={createNewCv}/>

        <Grid container justifyContent={"center"} style={{marginBottom: 50}}>
            <Typography variant={"caption"} className={"violetColor"} fontSize={20} align={"left"} display={"block"}
                        style={{
                            width: "100%",
                            marginLeft: 50,
                            fontWeight: "600",
                            marginBottom: 10,
                            marginTop:10
                            // }}>{wordsWithLang.cvApp.makeNewCV}</Typography>
                        }}>{wordsWithLang.cvApp.faq}</Typography>

            <Grid item sm={11} xs={11}>
                <CvAppFAQ/>
            </Grid>
            <Grid item sm={11} xs={11} gap={1}>
                <Grid item xs={12}>
                    <Divider variant="middle" size="large"
                             style={{margin: 20, marginTop: 20, fontSize: 15, border: ".5px solid rgb(212 212 212)"}}/>
                </Grid>
                <Typography variant={"caption"} className={"violetColor"} fontSize={20} align={"left"} display={"block"}
                            style={{
                                // width: "100%",
                                marginLeft: 30,
                                fontWeight: "600",
                                marginBottom: 10
                                // }}>{wordsWithLang.cvApp.makeNewCV}</Typography>
                            }}>{wordsWithLang.cvApp.selectLanguage}</Typography>
                <CVLanguageSelection wordsWithLang={wordsWithLang} selectLanguage={selectLanguage} newCvOrder={newCvOrder} createNewCv={createNewCv}/>
            </Grid>
            <Grid item xs={11} lg={4} md={6}>
                <Button variant={"contained"}
                        fullWidth
                        className={"btn"}
                        style={{height: 50, marginTop: 25}}
                        startIcon={<AutoAwesome fontSize="medium" style={{color: 'white'}}/>}
                        onClick={() => {
                            createNewCv()
                        }}
                >
                    <Typography justifyContent={"center"} fontSize={20}>{wordsWithLang.cvApp.start}</Typography>
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Divider variant="middle" size="large"
                         style={{margin: 20, marginTop: 50, fontSize: 15, border: ".5px solid rgb(212 212 212)"}}/>
            </Grid>
        </Grid>

    )
}

export default CvAppMainCreateNewCV
