import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, TextField} from "@mui/material";
import {ArrowDownward} from "@mui/icons-material";
import {useDispatch} from "react-redux";

const CompetenceAccordion = ({
    wordsWithLang,
    expanded,
    setExpanded,
    cvAppConfigCvData,
    competencesList,
    updateCvDataInServer
                             }) => {

    const  dispatcher = useDispatch()
    const [competences, setCompetences] = useState(cvAppConfigCvData.competencies)
    const [customCompetence, setCustomCompetence] = useState("")
    const [allCompetencies , setAllCompetencies] = useState(competencesList)


    // const toCamelCase = (str) =>
    //     str
    //         .split(" ")
    //         .map((word, index) =>
    //             index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1).toLowerCase()
    //         )
    //         .join("");

    useEffect(()=>{
        competences.map((key,value)=>{
            if(!Object.entries(allCompetencies).includes(key)){
                setAllCompetencies((allCompetencies) => ({
                    [key]: key.split("_").join(" "), // Place the new entry first
                    ...allCompetencies // Then spread the rest of the existing properties
                }));
            }
        })

    },[])




    return (
        <>
            <Grid item xs={12} style={{margin: 10}}>
                <TextField id={"customCompetence"}
                           onChange={(e) => {
                               setCustomCompetence(e.target.value)
                           }}
                           label={"Add new one"}
                           value={customCompetence}
                           style={{margin:"10px", display:"flex"}}
                           // helperText={personalData.address!== null ?personalData.address.length + "/100 " + errorMessagesForPersonalData.about:null}
                           // error={errorMessagesForPersonalData.about !== ""} fullWidth margin="normal"
                           variant="outlined"
                           InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 100}}}/>

                <Button className={"addButton"} style={{margin:"10px"}}
                onClick={()=>{
                    setAllCompetencies((allCompetencies) => ({
                        [customCompetence.split(" ").join("_")]: customCompetence, // Place the new entry first
                        ...allCompetencies // Then spread the rest of the existing properties
                    }));
                    competences.push( customCompetence.split(" ").join("_"))
                    console.log(competences)
                setCustomCompetence('')}}>
                    {wordsWithLang.cvApp.cvAppForms.addInfos}
                </Button>

                <Divider/>
            </Grid>



            {Object.entries(allCompetencies).map(([key, value]) => (
                // <Button size="small" margin="normal" variant="outlined">
                <FormControl key={key} size="small"
                             variant="outlined" style={{
                    border: "1px solid",
                    borderColor: "#b4b4b4",
                    borderRadius: "20px",
                    margin: "8px",
                    paddingLeft: "10px"
                }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                // checked={cvAppConfigCvData.competencies && cvAppConfigCvData.competencies.includes(key)}
                                checked={competences && competences.includes(key)}
                                key={key}
                                onClick={() => {
                                    console.log(competences)
                                    if (!competences.includes(key)) {
                                        setCompetences([...competences, key])
                                    } else {
                                        setCompetences(competences.filter(item => item !== key))
                                    }
                                }}
                                sx={{borderRadius: 5}}
                            />
                        }
                        label={value}
                    />
                    {/*<FormHelperText>{errorMessagesForEduData.eduIsContinue}</FormHelperText>*/}
                </FormControl>
                // </Button>
            ))}
            <Grid item xs={12} style={{margin: 10}}>
                <Divider/>
            </Grid>


            <Grid container spacing={1} justifyContent={"space-between"}>
                <Grid item xs={12} md={12} lg={8}>

                </Grid>
                <Grid item xs={12} lg={3}>
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={12} md={6} lg={12}>
                            <Button
                                variant="contained"
                                startIcon={<ArrowDownward fontSize="medium"
                                                          style={{color: 'white'}}/>}
                                className="addButton continueButton"
                                size="small"
                                style={{width: '100%', marginTop: 15}}
                                onClick={() => {
                                    let newCvAppConfigCvData = cvAppConfigCvData;
                                    newCvAppConfigCvData.competencies=competences;
                                    dispatcher({
                                        type: "CV_DATA_UPDATE",
                                        payload: newCvAppConfigCvData
                                    })

                                    updateCvDataInServer("competencies", newCvAppConfigCvData);
                                    setExpanded(expanded.replace(/panel(\d+)/g, (m, p1) => `panel${+p1 + 1}`))
                                }}
                            >
                                {wordsWithLang.cvApp.cvAppForms.continue}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



        </>
    );
};

export default CompetenceAccordion;
