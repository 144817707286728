import React from 'react';
import './App.css';
import Main from "./pages/main";
import {createTheme, ThemeProvider} from "@mui/material";
import {hot} from "react-hot-loader/root";
import PullToRefresh from "react-simple-pull-to-refresh";


// const defaultTheme = createTheme();
const violet = {
    50: "#ede9f9",    // Very light
    100: "#d2c7f0",   // Light
    200: "#b8a5e6",   // Lighter
    300: "#9f84dd",   // Medium-light
    400: "#7e62d3",   // Medium
    500: "#4C3198FF", // Primary color (main)
    600: "#432c88",   // Slightly darker
    700: "#3a2578",   // Dark
    800: "#311e68",   // Darker
    900: "#281757",   // Very dark
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#1778f2',
        },
        secondary: {
            main: '#e50914'
        },
        success: {
            main:'#07C04D'
        },
        violet: {
            main: violet[500],
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        }}
});
const App = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <div id={"root"} className="App">
                {/*<PullToRefresh onRefresh={()=>{window.location.reload()}} pullingContent={"Yeniləmək üçün çəkin..."}>*/}
                <Main/>
                {/*</PullToRefresh>*/}
            </div>
        </ThemeProvider>

    );
}
// export default App;
export default hot(App);

