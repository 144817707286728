import React, {useEffect, useRef, useState} from 'react';
import {Box, IconButton} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CvAppTemplateCard from "../cvAppTemplateCard";

const TemplateScroll = ({cvLanguage, templates, selectTemplate, selectedTemplate }) => {
    const scrollRef = useRef(null);
    const containerWidth = window.innerWidth;
    // const cardWidth = containerWidth<900?containerWidth*0.9:containerWidth/3;
    const cardWidth = 400;
    const gap = 30; // Gap between cards in pixels

    const [currentIndex, setCurrentIndex] = useState(0);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const slideContainerRef = useRef(null);
    const [centerIndex, setCenterIndex] = useState(0);

    const scrollToIndex = (index) => {
        if (scrollRef.current) {

            const scrollPosition = (index * cardWidth+gap)+gap+gap // Adjust for gap
            scrollRef.current.scrollTo({
                left: scrollPosition,
                behavior: "smooth",
            });
        }
    };

    // Calculate the scroll amount dynamically based on card width and gap
    const scrollAmount = cardWidth + gap;

    // Calculate the left margin to center the first card
    const firstCardMarginLeft = window.innerWidth<900?'0px 50px':'0px '+cardWidth/2+'px'

    // Function to scroll left (previous) and wrap around to end if at the start
    const scrollLeft = () => {
        if (scrollRef.current) {
            const scrollContainer = scrollRef.current;

            // If we are at the start of the carousel (scrollLeft === 0), scroll to the end
            if (scrollContainer.scrollLeft === 0) {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollWidth - scrollContainer.clientWidth,
                    behavior: 'smooth',
                });
            } else {
                // Otherwise, scroll left as usual
                scrollContainer.scrollBy({
                    left: -scrollAmount,
                    behavior: 'smooth',
                });
            }
        }
    };

    // Function to scroll right (next) and wrap around to start if at the end
    const scrollRight = () => {
        if (scrollRef.current) {
            const scrollContainer = scrollRef.current;
            const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

            // Allow a small tolerance to detect if we're "at the end"
            const isNearEnd = scrollContainer.scrollLeft >= maxScrollLeft - 5;

            if (isNearEnd) {
                // If near the end, scroll back to the start
                scrollContainer.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                // Otherwise, scroll right as usual
                scrollContainer.scrollBy({
                    left: scrollAmount,
                    behavior: 'smooth',
                });
            }
        }
    };


    const handleTouchStart = (e) => {
        setTouchStart(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStart - touchEnd > 200) {
            scrollRight(); // Swipe left
        }
        if (touchStart - touchEnd < -200) {
            scrollLeft(); // Swipe right
        }
    };


    useEffect(() => {
        if (selectedTemplate) {
            const selectedIndex = templates.findIndex(template => template.id === selectedTemplate);

            // If the template is found, scroll to its index
            if (selectedIndex !== -1) {
                setCurrentIndex(selectedIndex);
                scrollToIndex(selectedIndex);
            }
        }
    }, [selectedTemplate, templates]);


    return (
        <Box
            position='relative'
            display='flex'
            alignItems='center'
            justifyContent='center'
            maxWidth={containerWidth}
            // style={{marginLeft:20}}
            // ref={scrollRef}
        >
            {/* Left Scroll Button (Previous) */}
            <IconButton

                onClick={scrollLeft}
                className={"addButton"}
                sx={{
                    height:50,
                    width:50,
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                }}
            >
                <ArrowBackIosIcon style={{"paddingLeft":3, marginRight:-3}} />
            </IconButton>

            {/* Scrollable Cards Container */}
            <Box
                onTouchStart={(e)=>handleTouchStart(e)}
                onTouchEnd={(e)=>handleTouchEnd(e)}
                onTouchMove={(e)=>handleTouchMove(e)}
                ref={scrollRef}
                display='flex'
                overflow='auto'
                height={"110%"}
                sx={{
                    scrollBehavior: 'smooth',
                    gap: `${gap}px`,
                    width: `${containerWidth}px`, // Adjust width of the whole carousel as needed
                    padding: firstCardMarginLeft, // Optional: to add spacing around the carousel

                }}
            >
                {/* Sample Cards */}

                {templates.map((template, index) => (
                    <Box key={template.id}
                        sx={{
                        minWidth: `${cardWidth}px`,
                            // marginTop:2,
                        marginBottom:4,
                        // flexShrink: 0,
                        // Apply left margin only for the first card
                        // marginLeft: index === 0 ? `${firstCardMarginLeft}px` : 0,
                        // Add margin for the last card to include gap at the end
                        // marginRight: index === templates.length - 1 ? `${firstCardMarginLeft}px` : 0,
                    }}
                    >
                        <CvAppTemplateCard
                            cvLanguage={cvLanguage}
                            template={template}
                            selectTemplate={selectTemplate}
                            selectedTemplate={selectedTemplate}
                        />
                    </Box>
                ))}

            </Box>

            {/* Right Scroll Button (Next) */}
            <IconButton
                onClick={scrollRight}
                className={"addButton"}
                sx={{
                    height:50,
                    width:50,
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                }}
            >
                <ArrowForwardIosIcon />
            </IconButton>
        </Box>
    );
};

export default TemplateScroll;
