import React, {useState} from 'react';
import {Box, Button, Divider, Grid, TextField} from "@mui/material";
import {Add, AutoAwesome, Clear, Edit} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const NoteAccordion = ({wordsWithLang, cvAppConfigCvData,
                           checkAllErrors, updateCvDataInServer}) => {
    const [note, setNote] = useState("");
    const dispatcher = useDispatch();
    const navigate = useNavigate()

    return (
        <>

            {cvAppConfigCvData.note == null || cvAppConfigCvData.note.length === 0 ?
                <Box display={"block"} alignItems="center" marginY={2}>
                    <Box alignItems={"center"} display={"flex"} style={{
                        width: "100%",
                        fontWeight: "400",
                        color: "gray",
                        fontSize: "12px",
                        fontStyle: "italic"
                    }}>
                        <Edit fontSize="small"
                              style={{marginRight: 5}}/> {wordsWithLang.cvApp.cvAppForms.noAdditionalNotes}
                    </Box>
                </Box>
                :
                (<Box display={"block"} alignItems="center" marginY={2}>
                        <Box alignItems={"center"} display={"flex"} style={{
                            width: "100%",
                            fontWeight: "400",
                            color: "gray",
                            fontSize: "13px",
                            fontStyle: "italic"

                        }}>
                            <Edit fontSize="small"
                                  style={{marginRight: 5}}/>
                            {cvAppConfigCvData.note}
                            {/*<Button style={{marginLeft:10, color:"#e50914"}} onClick={() => {*/}
                            {/*    deleteRef(key)*/}
                            {/*}}>{wordsWithLang.cvApp.cvAppForms.delete}<Delete style={{color:"#e50914"}}/></Button>*/}
                        </Box>
                    </Box>
                )}

            <Grid item xs={12} style={{margin: 10}}>
                <Divider/>
            </Grid>


            <TextField id={"addNotes"}
                       value={note}
                       onChange={(e) => {
                           setNote(e.target.value)
                       }}
                       multiline minRows={4}
                // helperText={errorMessagesForRefData.refName}
                       helperText={note.length + "/300 "}

                // error={errorMessagesForRefData.refName !== ""}
                       fullWidth margin="normal"
                       label={wordsWithLang.cvApp.cvAppForms.additionalNotes} variant="outlined"
                       InputProps={{sx: {borderRadius: 5}, inputProps: {maxLength: 300}}}/>

            <Grid container spacing={1} justifyContent={"space-between"}>
                <Grid item xs={12} md={12} lg={8}>
                    {cvAppConfigCvData.note ?
                        (<Button
                            id={"noteCommit"}
                            onClick={() => {
                                cvAppConfigCvData.note = note
                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                setNote("")
                            }}
                            variant="contained"
                            startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                            className={note === "" ? "addButton disabledButton" : "addButton"}
                            size="small"
                            style={{marginTop: 15}}
                        >
                            {wordsWithLang.cvApp.cvAppForms.updateInfos}
                        </Button>) :
                        (<Button
                            id={"noteCommit"}
                            onClick={() => {
                                cvAppConfigCvData.note = note
                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                                setNote("")
                            }}
                            variant="contained"
                            startIcon={<Add fontSize="medium" style={{color: 'white'}}/>}
                            className={note === "" ? "addButton disabledButton" : "addButton"}
                            size="small"
                            style={{marginTop: 15}}
                        >
                            {wordsWithLang.cvApp.cvAppForms.addInfos}
                        </Button>)}

                    <Button className={"addButton cleanButton"} size="small"
                            startIcon={<Clear fontSize={"medium"} style={{color: "white"}}/>}
                            style={{marginTop: 15, marginLeft: 8}}
                            onClick={() => {
                                cvAppConfigCvData.note = ""
                                dispatcher({type: "CV_DATA_UPDATE", payload: cvAppConfigCvData})
                            }}>
                        {wordsWithLang.cvApp.cvAppForms.clean}
                    </Button>

                </Grid>
                <Grid item xs={12} lg={3}>
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={12} md={6} lg={12}>
                            <Button
                                variant="contained"
                                startIcon={<AutoAwesome fontSize="medium"
                                                        style={{color: 'white'}}/>}
                                className="addButton continueButton btn"
                                size="small"
                                style={{width: '100%', marginTop: 15}}
                                onClick={() => {
                                    if (checkAllErrors()) {
                                        updateCvDataInServer("note");
                                        dispatcher({type: "CV_APP_CHANGE_SCREEN", payload: "template"})
                                        navigate({pathname:'/cv',hash: "template"})

                                    }
                                }
                                }
                            >
                                {wordsWithLang.cvApp.cvAppForms.choseTemplate}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



        </>
    );
};

export default NoteAccordion;
