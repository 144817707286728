import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AdminPanelSettings, Create, Delete, Save} from "@mui/icons-material";
import axios from "axios";
import apiUrls from "../apiUrls";
import NewVacancyDialogPart from "../components/newVacancyDialogPart";
import AddEditBlogDialogPart from "../components/addEditBlogDialogPart";
import AddNewForm from "../components/AddNewForm";


const AdminDialog = () => {
    // const mainConfig = useSelector(state => state.mainConfig)
    const dialogPage = 0
    // const userConfig = useSelector(state => state.userConfig)
    const adminConfig = useSelector(state => state.adminConfig);
    const [vacancyItemAdmin, setVacancyItemAdmin] = useState({
        id: "",
        srcId: "",
        source: "",
        company: {
            name: "",
            slug: "",
            logo: "",
            miniLogo: "",
            vacancyCount: "",
            info: "",
            address: "",
            email: "",
            phones: [],
            sites: [],
        },
        category: "",
        title: "",
        content: "",
        salary: "",
        slug: "",
        actionDataType: "",
        actionData: "",

        workTime: "",
        area: "",

        isNew: "",
        isVip: "",
        isFavorite: "",
        deadline: "",
        created: "",
        viewCount: "",
        isActive: "",
    })

    // const [companyItem, setCompanyItem] = useState({
    //     name: "",
    //     slug: "",
    //     logo: "",
    //     miniLogo: "",
    //     vacancyCount: "",
    //     info: "",
    //     address: "",
    //     email: "",
    //     phones: [],
    //     sites: [],
    // })
    // const [selectedCategory, setSelectedCategory] = useState("")
    //
    // const [categories, setCategories] = useState([])
    // const [registerUser, setRegisterUser] = useState({
    //     age: "18-23",
    //     gender: "male",
    //     name: "",
    //     email: "",
    //     phoneNumber: "",
    //     businessCategorySlug: "all",
    //     pass: "",
    //     repass: "",
    // })
    const dispatcher = useDispatch()
    const theme = useTheme();
    const [cvOrderId, setCvOrderId] = useState("")
    const handleClose = () => {
        dispatcher({type: "OPEN_ADMIN_DIALOG", payload: {adminVacId: "", adminDialogState: false}})
    };

    // function containsAnyLetters(str) {
    //     return /[a-zA-Z]/.test(str);
    // }

    // const formChange = (e) => {
    //     switch (e.target.id) {
    //         case "name" : {
    //             return true;
    //         }
    //     }
    //     switch (e.target.name) {
    //         case "businessCategorySlug" : {
    //             setRegisterUser({...registerUser, businessCategorySlug: e.target.value});
    //             return true;
    //         }
    //     }
    // }


    const saveVacancy = () => {
        axios.post(apiUrls.baseApiUrl + apiUrls.postAdminVacancy,vacancyItemAdmin,
            {withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(
            resp => {
                setVacancyItemAdmin(resp.data)
                dispatcher({type:"OPEN_SNACK",payload:{
                        isSnackOpen:true,
                        snackType:resp.status===200?"success":"error",
                        snackMessage:resp.status===200?"Vacancy Saved":"Some Error."
                    }})
            }
        )
    }

    const deleteVacancy = () => {
        axios.delete(apiUrls.baseApiUrl + apiUrls.delAdminVacancy.replace(":id",vacancyItemAdmin.id),
            {withCredentials: true,
                headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
            }).then(
            resp => {
                dispatcher({type:"OPEN_SNACK",payload:{
                        isSnackOpen:true,
                        snackType:resp.status===200?"success":"error",
                        snackMessage:resp.status===200?"Vacancy deleted":"Some Error."
                    }})
            }
        )
    }

    const changeForm = (e) => {
      switch (e.target.id){
          case "id":{
              setVacancyItemAdmin({...vacancyItemAdmin,id:e.target.value})
              break;
          }
          case "srcId":{
              setVacancyItemAdmin({...vacancyItemAdmin,srcId:e.target.value})
              break;
          }
          case "source":{
              setVacancyItemAdmin({...vacancyItemAdmin,source:e.target.value})
              break;
          }
          // case "company":{
          //     setVacancyItemAdmin({...vacancyItemAdmin,company:e.target.value})
          //     break;
          // }
          case "category":{
              setVacancyItemAdmin({...vacancyItemAdmin,category:e.target.value})
              break;
          }
          case "title":{
              setVacancyItemAdmin({...vacancyItemAdmin,title:e.target.value})
              break;
          }
          case "content":{
              setVacancyItemAdmin({...vacancyItemAdmin,content:e.target.value})
              break;
          }
          case "salary":{
              setVacancyItemAdmin({...vacancyItemAdmin,salary:e.target.value})
              break;
          }
          case "slug":{
              setVacancyItemAdmin({...vacancyItemAdmin,slug:e.target.value})
              break;
          }
          case "actionDataType":{
              setVacancyItemAdmin({...vacancyItemAdmin,actionDataType:e.target.value})
              break;
          }
          case "actionData":{
              setVacancyItemAdmin({...vacancyItemAdmin,actionData:e.target.value})
              break;
          }
          case "workTime":{
              setVacancyItemAdmin({...vacancyItemAdmin,workTime:e.target.value})
              break;
          }
          case "area":{
              setVacancyItemAdmin({...vacancyItemAdmin,area:e.target.value})
              break;
          }
          case "isNew":{
              setVacancyItemAdmin({...vacancyItemAdmin,isNew:e.target.value})
              break;
          }
          case "isVip":{
              setVacancyItemAdmin({...vacancyItemAdmin,isVip:e.target.value})
              break;
          }
          case "isFavorite":{
              setVacancyItemAdmin({...vacancyItemAdmin,isFavorite:e.target.value})
              break;
          }
          case "deadline":{
              setVacancyItemAdmin({...vacancyItemAdmin,deadline:e.target.value})
              break;
          }
          case "created":{
              setVacancyItemAdmin({...vacancyItemAdmin,created:e.target.value})
              break;
          }
          case "viewCount":{
              setVacancyItemAdmin({...vacancyItemAdmin,viewCount:e.target.value})
              break;
          }
          case "isActive":{
              setVacancyItemAdmin({...vacancyItemAdmin,isActive:e.target.value})
              break;
          }


          case "company-name":{
              setVacancyItemAdmin({...vacancyItemAdmin,company: {...vacancyItemAdmin.company,name:e.target.value}})
              break;
          }case "company-slug":{
              setVacancyItemAdmin({...vacancyItemAdmin,company: {...vacancyItemAdmin.company,slug:e.target.value}})
              break;
          }case "company-logo":{
              setVacancyItemAdmin({...vacancyItemAdmin,company: {...vacancyItemAdmin.company,logo:e.target.value}})
              break;
          }case "company-miniLogo":{
              setVacancyItemAdmin({...vacancyItemAdmin,company: {...vacancyItemAdmin.company,miniLogo:e.target.value}})
              break;
          }case "company-vacancyCount":{
              setVacancyItemAdmin({...vacancyItemAdmin,company: {...vacancyItemAdmin.company,vacancyCount:e.target.value}})
              break;
          }case "company-info":{
              setVacancyItemAdmin({...vacancyItemAdmin,company: {...vacancyItemAdmin.company,info:e.target.value}})
              break;
          }case "company-address":{
              setVacancyItemAdmin({...vacancyItemAdmin,company: {...vacancyItemAdmin.company,address:e.target.value}})
              break;
          }case "company-email":{
              setVacancyItemAdmin({...vacancyItemAdmin,company: {...vacancyItemAdmin.company,email:e.target.value}})
              break;
          }case "company-phones":{
              setVacancyItemAdmin({...vacancyItemAdmin,company: {...vacancyItemAdmin.company,phones:e.target.value}})
              break;
          }case "company-sites":{
              setVacancyItemAdmin({...vacancyItemAdmin,company: {...vacancyItemAdmin.company,sites:e.target.value}})
              break;
          }
          default:{
              return null;
          }
      }
    }
    const [createVacancy,setCreateVacancy] = useState(false)
    const [addEditBlog,setAddEditBlog] = useState(false)
    const [addForm,setAddForm] = useState(false)

    useEffect(() => {
        if (adminConfig.vacancyItem.id) {
            axios.get(apiUrls.baseApiUrl + apiUrls.getAdminVacancy.replace(":id", adminConfig.vacancyItem.id), {withCredentials: true}).then(
                resp => {
                    setVacancyItemAdmin(resp.data)
                }
            )
        }
    }, [adminConfig.adminDialogState === true])

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFilesChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...files]);
    };
    const formData = new FormData();
    const handleUpload = (type) => {
        if(type === "logo") {
            // Do something with the selected files
            if (selectedFiles.length > 0) {
                console.log('Selected files:', selectedFiles);

                selectedFiles.forEach((file) => {
                    formData.append('files', file);
                });
                axios.post(apiUrls.baseApiUrl + apiUrls.postAdminLogos, formData, {
                    withCredentials: true,
                    headers: {
                        'Access-Control-Allow-Origin': 'http://localhost:3000',
                        'Content-Type': 'multipart/form-data'
                    }

                })
                    .then(resp => {
                        setSelectedFiles([])
                        alert(resp.data)
                    })
            }

        }
        if(type === "html") {
            // Do something with the selected files
            if (selectedFiles.length > 0) {
                console.log('Selected files:', selectedFiles);

                selectedFiles.forEach((file) => {
                    formData.append('files', file);
                });
                axios.post(apiUrls.baseApiUrl + apiUrls.postAdminHtml.replace("{cvOrderId}",cvOrderId), formData, {
                    withCredentials: true,
                    headers: {
                        'Access-Control-Allow-Origin': 'http://localhost:3000',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(resp => {
                        setSelectedFiles([])
                        setCvOrderId("")
                        alert(resp.data)
                    })
            }

        }
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={adminConfig.adminDialogState}
            onClose={handleClose}
            PaperProps={{
                style: {borderRadius: 15}
            }}
        >
            {dialogPage === 0 ? (
                <div>
                    <DialogTitle style={{
                        backgroundColor: theme.palette.text.primary,
                        color: "white"
                    }}><Typography display={"block"}><AdminPanelSettings fontSize={"medium"} style={{
                        verticalAlign: "bottom",
                        marginRight: 10
                    }}/>{"Admin dialog"}</Typography>
                        <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}
                                variant={"contained"} color={"secondary"}
                                onClick={deleteVacancy}
                                startIcon={<Delete fontSize="small"/>}
                        >{"DEL"}</Button>

                        <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}
                                variant={"contained"} color="primary"
                                onClick={saveVacancy}
                                startIcon={<Save fontSize="small"/>}
                        >{"save / update"}</Button>

                        <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}
                                variant={"outlined"} color={"secondary"}
                                onClick={()=>{setCreateVacancy(!createVacancy)}}
                                startIcon={<Create fontSize="small"/>}
                        >{!createVacancy?"create vacancy":"return to edit"}</Button>

                        <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}
                                variant={"outlined"} color={"secondary"}
                                onClick={()=>{setAddEditBlog(!addEditBlog)}}
                                startIcon={<Create fontSize="small"/>}
                        >{!addEditBlog?"add/edit blog":"return to edit"}</Button>

                        <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}
                                variant={"outlined"} color={"secondary"}
                                onClick={()=>{setAddForm(!addForm)}}
                                startIcon={<Create fontSize="small"/>}
                        >{!addEditBlog?"add/edit form":"return to edit"}</Button>

                    </DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText>*/}
                        {addForm?<AddNewForm/>:null}

                        <Divider style={{marginTop: 20}}>
                            {/*<Typography variant="caption" color="textSecondary">*/}
                            {/*    Update LOGO*/}
                            {/*</Typography>*/}
                        </Divider>
                        {createVacancy?<NewVacancyDialogPart/>:(addEditBlog?<AddEditBlogDialogPart/>:(
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12} sm={8} style={{marginTop: 20}}>
                                <Tooltip title={vacancyItemAdmin.source.includes("jobsearch") ? vacancyItemAdmin.source + "/vacancies/" + vacancyItemAdmin.slug : vacancyItemAdmin.source}><Button size="small" variant={"outlined"} style={{marginRight:10}}><a style={{display: "block", color: "blue"}}
                                   href={vacancyItemAdmin.source.includes("jobsearch") ? vacancyItemAdmin.source + "/vacancies/" + vacancyItemAdmin.slug : vacancyItemAdmin.source}>{"SOURCE"}</a>
                                </Button></Tooltip>
                                <Button size="small" variant={"outlined"} style={{marginRight:10}}><a style={{display: "block", color: "blue"}}
                                        href={vacancyItemAdmin.company.miniLogo && vacancyItemAdmin.company.miniLogo.includes("jobsearch") ? vacancyItemAdmin.company.miniLogo : vacancyItemAdmin.company.miniLogo}>{"LOGO URL"}</a>
                                </Button>
                                <Button size="small" variant={"outlined"} style={{marginRight:10}}><a style={{display: "block", color: "blue"}}
                                        href={"https://bakujobs.az/api/public/vacancyPreview/" + vacancyItemAdmin.id + ".jpg"}>{"VACANCY PREVIEW IMAGE"}</a>
                                </Button>
                                <br/><br/>
                                <Divider>
                                    <Typography variant="caption" color="textSecondary">
                                        Update LOGO
                                    </Typography>
                                </Divider>
                                {/*<InputLabel id="actionDataType-label" style={{fontWeight: "bold"}}>LOGO</InputLabel>*/}
                                <div>
                                    <input type="file" multiple onChange={handleFilesChange} style={{float:"left", width:"78%",padding:"4px"}}/>
                                    <Button variant={"outlined"} size="small" style={{display:"inline"}} onClick={()=>{handleUpload("logo")}}>Upload Logo</Button>
                                </div>
                                <br/>
                                <Divider>
                                    <Typography variant="caption" color="textSecondary">
                                        Update CV HTML
                                    </Typography>
                                </Divider>
                                <br/>
                                {/*<InputLabel id="actionDataType-label" style={{fontWeight: "bold"}}>HTML</InputLabel>*/}
                                <TextField variant={"standard"} error={cvOrderId===""} label={"cv order id"} size="small" value={cvOrderId} onChange={(e)=> {
                                    setCvOrderId(e.target.value)
                                }}/>
                                <br/>
                                <br/>
                                <div>
                                    <input type="file" multiple onChange={handleFilesChange} style={{float:"left", width:"78%",padding:"4px"}}/>
                                    <Button variant="outlined" size="small" style={{display:"inline"}} onClick={()=>{handleUpload("html")}}>Upload HTML</Button>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                <Divider>
                                    <Typography variant="caption" color="textSecondary">
                                        Update Current Vacancy
                                    </Typography>
                                </Divider>
                                <br/>

                                <InputLabel id="actionDataType-label">actionDataType</InputLabel>
                                <Select
                                    labelId="actionDataType-label"
                                    id="actionDataType"
                                    value={vacancyItemAdmin.actionDataType ? vacancyItemAdmin.actionDataType : ""}
                                    label="actionDataType"
                                    onChange={(e) => {
                                        // e.target.value = e.value
                                        e.target.id = "actionDataType"
                                        changeForm(e)
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value={"email"}>Email</MenuItem>
                                    <MenuItem value={"phoneNumber"}>Phone Number</MenuItem>
                                    <MenuItem value={"link"}>Link</MenuItem>
                                </Select>

                                {/*<TextField style={{marginTop:10}} fullWidth label={"actionDataType"} value={vacancyItemAdmin.actionDataType?vacancyItemAdmin.actionDataType:""} id="actionDataType" onChange={(e)=>{changeForm(e)}} />*/}
                                <TextField fullWidth label={"actionData"}
                                           value={vacancyItemAdmin.actionData ? vacancyItemAdmin.actionData : ""}
                                           id="actionData" onChange={(e) => {
                                    changeForm(e)
                                }}/>

                                <Divider style={{marginTop: 20, marginBottom: 20}}/>

                                <TextField margin={"dense"} disabled fullWidth label={"id"}
                                           value={vacancyItemAdmin.id ? vacancyItemAdmin.id : ""} id="id"
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                <TextField margin={"dense"} disabled fullWidth label={"srcId"}
                                           value={vacancyItemAdmin.srcId ? vacancyItemAdmin.srcId : ""} id="srcId"
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                <TextField margin={"dense"} fullWidth label={"source"}
                                           value={vacancyItemAdmin.source ? vacancyItemAdmin.source : ""} id="source"
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                {/*<TextField margin={"dense"} fullWidth label={"category"} value={vacancyItemAdmin.category?vacancyItemAdmin.category:"} id="category" onChange={(e)=>{changeForm(e)}} />*/}
                                <TextField margin={"dense"} fullWidth label={"title"}
                                           value={vacancyItemAdmin.title ? vacancyItemAdmin.title : ""} id="title"
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                <TextField multiline maxRows={10} margin={"dense"} fullWidth label={"content"}
                                           value={vacancyItemAdmin.content ? vacancyItemAdmin.content : ""} id="content"
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                <TextField margin={"dense"} fullWidth label={"salary"}
                                           value={vacancyItemAdmin.salary ? vacancyItemAdmin.salary : ""} id="salary"
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                <TextField margin={"dense"} fullWidth label={"slug"}
                                           value={vacancyItemAdmin.slug ? vacancyItemAdmin.slug : ""} id="slug"
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                <TextField margin={"dense"} fullWidth label={"workTime"}
                                           value={vacancyItemAdmin.workTime ? vacancyItemAdmin.workTime : ""}
                                           id="workTime" helperText={"full-time / part-time / internship"}
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                <TextField margin={"dense"} fullWidth label={"area"}
                                           value={vacancyItemAdmin.area ? vacancyItemAdmin.area : ""} id="area"
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                <TextField margin={"dense"} fullWidth label={"isNew"}
                                           value={vacancyItemAdmin.isNew ? vacancyItemAdmin.isNew : ""} id="isNew"
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                <TextField margin={"dense"} fullWidth label={"isVip"}
                                           value={vacancyItemAdmin.isVip ? vacancyItemAdmin.isVip : ""} id="isVip"
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                <TextField margin={"dense"} fullWidth label={"isFavorite"}
                                           value={vacancyItemAdmin.isFavorite ? vacancyItemAdmin.isFavorite : ""}
                                           id="isFavorite" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} fullWidth label={"deadline"}
                                           value={vacancyItemAdmin.deadline ? vacancyItemAdmin.deadline : ""}
                                           id="deadline" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} fullWidth label={"created"}
                                           value={vacancyItemAdmin.created ? vacancyItemAdmin.created : ""} id="created"
                                           onChange={(e) => {
                                               changeForm(e)
                                           }}/>
                                <TextField margin={"dense"} fullWidth label={"viewCount"}
                                           value={vacancyItemAdmin.viewCount ? vacancyItemAdmin.viewCount : ""}
                                           id="viewCount" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} fullWidth label={"isActive"}
                                           value={vacancyItemAdmin.isActive ? vacancyItemAdmin.isActive : ""}
                                           id="isActive" onChange={(e) => {
                                    changeForm(e)
                                }}/>


                                <Divider style={{marginTop: 20, marginBottom: 20}}><Typography color="primary"
                                                                                               variant={"caption"}>company</Typography></Divider>
                                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"name"}
                                           value={vacancyItemAdmin.company.name ? vacancyItemAdmin.company.name : ""}
                                           id="company-name" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"slug"}
                                           value={vacancyItemAdmin.company.slug ? vacancyItemAdmin.company.slug : ""}
                                           id="company-slug" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"logo"}
                                           value={vacancyItemAdmin.company.logo ? vacancyItemAdmin.company.logo : ""}
                                           id="company-logo" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"miniLogo"}
                                           value={vacancyItemAdmin.company.miniLogo ? vacancyItemAdmin.company.miniLogo : ""}
                                           id="company-miniLogo" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"vacancyCount"}
                                           value={vacancyItemAdmin.company.vacancyCount ? vacancyItemAdmin.company.vacancyCount : ""}
                                           id="company-vacancyCount" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"info"}
                                           value={vacancyItemAdmin.company.info ? vacancyItemAdmin.company.info : ""}
                                           id="company-info" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"address"}
                                           value={vacancyItemAdmin.company.address ? vacancyItemAdmin.company.address : ""}
                                           id="company-address" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"email"}
                                           value={vacancyItemAdmin.company.email ? vacancyItemAdmin.company.email : ""}
                                           id="company-email" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"phones"}
                                           value={vacancyItemAdmin.company.phones ? vacancyItemAdmin.company.phones : ""}
                                           id="company-phones" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <TextField margin={"dense"} style={{marginLeft: 20}} fullWidth label={"sites"}
                                           value={vacancyItemAdmin.company.sites ? vacancyItemAdmin.company.sites : ""}
                                           id="company-sites" onChange={(e) => {
                                    changeForm(e)
                                }}/>
                                <Divider style={{marginTop: 20, marginBottom: 20}}/>

                            </Grid>

                        </Grid>))}
                        {/*</DialogContentText>*/}

                    </DialogContent>
                    {/*    <DialogActions style={{boxShadow: "0px 0 3px rgba(0, 0, 0, 0.5)"}}>*/}
                    {/*        <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}*/}
                    {/*                variant={"outlined"} color={"secondary"}*/}
                {/*                onClick={handleClose}><Close fontSize="small"/>{words.loginDialog.closeButton}*/}
                {/*        </Button>*/}
                {/*        <Button style={{borderRadius: 10, marginTop: 10, marginBottom: 10, marginRight: 10}}*/}
                {/*                variant={"outlined"} color="primary"*/}
                {/*                onClick={saveVacancy}*/}
                {/*        >{words.header.login}<ArrowForward*/}
                {/*            fontSize="small"/></Button>*/}
                {/*    </DialogActions>*/}
                </div>
            ) : (<div>ERROR</div>)}
        </Dialog>
    );
};

export default AdminDialog;


