import React, {useRef, useState} from 'react';
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, LinearProgress,
    Radio,
    RadioGroup, Typography
} from "@mui/material";
import axios from "axios";
import apiUrls from "../../apiUrls";
import {ArrowDownward, ArrowUpward, Close} from "@mui/icons-material";
import {useDispatch} from "react-redux";

const CvAppPreviewDetailedViewDialog = ({wordsWithLang, newCvOrder, marginDialogOpen, setMarginDialogOpen, marginWait, setMarginWait}) => {
    const dispatcher = useDispatch();
    const previewRef = useRef()
    const [selectedSection, setSelectedSection] = useState("");
    const imageMarginChangeRequest = (side) => {
        if (selectedSection === "") {
            document.getElementById("marginSectionLabelId").style.color = "red"
            return
        }
        document.getElementById("marginSectionLabelId").style.color = "black"
        setMarginWait(true)

        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppChangeMargin
            .replace("{CvOrderId}", newCvOrder.id).replace("{section}", selectedSection).replace("{marginSide}", side)
            , {withCredentials: true}).then(() => {
            setMarginWait(false)
            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppGetCvById.replace("{CvOrderId}", newCvOrder.id),
                {
                    withCredentials: true,
                    headers: {'Access-Control-Allow-Origin': 'http://localhost:3000'}
                }).then(
                resp => {
                    dispatcher({type: "CV_DATA_UPDATE", payload: resp.data.cvData})
                    dispatcher({type: "CV_INSTANCE_UPDATE", payload: resp.data})
                }
            )
        })
    }


    return (
        <Dialog maxWidth={"lg"} open={marginDialogOpen} sx={{
            "& .MuiDialogContent-root": {
                padding: "20px", // Adjust the padding value as needed
            },
        }} onClose={() => {
            setMarginDialogOpen(false)
            setMarginWait(false)
            axios.get(apiUrls.baseApiUrl + apiUrls.cvAppConfirmMargins.replace("{CvOrderId}", newCvOrder.id), {withCredentials: true})
        }} fullWidth>
            {/*<DialogTitle>*/}
            {/*    <Typography variant={"body1"}*/}
            {/*                style={{marginBottom: 15}}*/}
            {/*    >{wordsWithLang.cvApp.cvAppForms.generalPreview}</Typography>*/}
            {/*</DialogTitle>*/}
            <DialogContent style={{ overflow: "hidden !important"}}>
                <Grid container justifyContent={"flex-start"} >
                    <Grid item xs={12} md={3} >
                        {/*<FormControl variant="outlined"*/}
                        {/*             style={{width: "100%", marginTop: 15, marginBottom: 15}}>*/}
                        {/*    <InputLabel*/}
                        {/*        id="marginSectionLabelId">{wordsWithLang.cvApp.cvAppForms.template.defaultSelect}</InputLabel>*/}
                        {/*    <Select*/}
                        {/*        labelId="marginSectionLabelId"*/}
                        {/*        id="marginSectionId"*/}
                        {/*        value={selectedSection}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            setSelectedSection(e.target.value)*/}
                        {/*            document.getElementById("marginSectionLabelId").style.color = "black"*/}
                        {/*            document.getElementById("marginSectionId").style.color = "black"*/}

                        {/*        }}*/}
                        {/*        label="Select"*/}
                        {/*    >*/}
                        {/*        <MenuItem*/}
                        {/*            value={"competencies-section"}>{wordsWithLang.cvApp.cvAppForms.template.competenciesSection}</MenuItem>*/}
                        {/*        <MenuItem*/}
                        {/*            value={"certificates-section"}>{wordsWithLang.cvApp.cvAppForms.template.certificatesSection}</MenuItem>*/}
                        {/*        <MenuItem*/}
                        {/*            value={"educations-section"}>{wordsWithLang.cvApp.cvAppForms.template.educationsSection}</MenuItem>*/}
                        {/*        <MenuItem*/}
                        {/*            value={"languages-section"}>{wordsWithLang.cvApp.cvAppForms.template.languagesSection}</MenuItem>*/}
                        {/*        <MenuItem*/}
                        {/*            value={"programs-section"}>{wordsWithLang.cvApp.cvAppForms.template.programsSection}</MenuItem>*/}
                        {/*        <MenuItem*/}
                        {/*            value={"references-section"}>{wordsWithLang.cvApp.cvAppForms.template.referencesSection}</MenuItem>*/}
                        {/*        <MenuItem*/}
                        {/*            value={"workExperiences-section"}>{wordsWithLang.cvApp.cvAppForms.template.workExperiencesSection}</MenuItem>*/}
                        {/*        <MenuItem*/}
                        {/*            value={"notes-section"}>{wordsWithLang.cvApp.cvAppForms.template.notesSection}</MenuItem>*/}

                        {/*    </Select>*/}
                        {/*</FormControl>*/}


                        <FormControl component="fieldset" style={{ width: "100%", marginTop: 5, marginBottom: 5 }}>
                            <FormLabel component="legend" id="marginSectionLabelId" color={"text"} style={{ marginBottom: 10, fontWeight:700, fontSize:20 }}>
                                {wordsWithLang.cvApp.cvAppForms.template.defaultSelect}
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="marginSectionLabelId"
                                id="marginSectionId"
                                value={selectedSection}
                                onChange={(e) => {
                                    setSelectedSection(e.target.value);
                                    document.getElementById("marginSectionLabelId").style.color = "black";
                                    document.getElementById("marginSectionId").style.color = "black";
                                }}
                            >
                                <Grid container spacing={0}>
                                    <Grid item xs={6} sm={6} md={12}>
                                        <FormControlLabel
                                            value="competencies-section"
                                            control={<Radio sx={{
                                                color: "var(--darkPurple)",
                                                '&.Mui-checked': {
                                                    color: "var(--darkPurple)",
                                                }}} />}
                                            label={wordsWithLang.cvApp.cvAppForms.template.competenciesSection}
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontWeight: selectedSection === "competencies-section" ? 700 : 400, // Dynamic font weight
                                                },
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={12}>
                                        <FormControlLabel
                                            value="certificates-section"
                                            control={<Radio sx={{
                                                color: "var(--darkPurple)",
                                                '&.Mui-checked': {
                                                    color: "var(--darkPurple)",
                                                }}} />}
                                            label={wordsWithLang.cvApp.cvAppForms.template.certificatesSection}
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontWeight: selectedSection === "certificates-section" ? 700 : 400, // Dynamic font weight
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={12}>
                                        <FormControlLabel
                                            value="educations-section"
                                            control={<Radio sx={{
                                                color: "var(--darkPurple)",
                                                '&.Mui-checked': {
                                                    color: "var(--darkPurple)",
                                                }}} />}
                                            label={wordsWithLang.cvApp.cvAppForms.template.educationsSection}
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontWeight: selectedSection === "educations-section" ? 700 : 400, // Dynamic font weight
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={12}>
                                        <FormControlLabel
                                            value="languages-section"
                                            control={<Radio sx={{
                                                color: "var(--darkPurple)",
                                                '&.Mui-checked': {
                                                    color: "var(--darkPurple)",
                                                }}} />}
                                            label={wordsWithLang.cvApp.cvAppForms.template.languagesSection}
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontWeight: selectedSection === "languages-section" ? 700 : 400, // Dynamic font weight
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={12}>
                                        <FormControlLabel
                                            value="programs-section"
                                            control={<Radio sx={{
                                                color: "var(--darkPurple)",
                                                '&.Mui-checked': {
                                                    color: "var(--darkPurple)",
                                                }}} />}
                                            label={wordsWithLang.cvApp.cvAppForms.template.programsSection}
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontWeight: selectedSection === "programs-section" ? 700 : 400, // Dynamic font weight
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={12}>
                                        <FormControlLabel
                                            value="references-section"
                                            control={<Radio sx={{
                                                color: "var(--darkPurple)",
                                                '&.Mui-checked': {
                                                    color: "var(--darkPurple)",
                                                }}} />}
                                            label={wordsWithLang.cvApp.cvAppForms.template.referencesSection}
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontWeight: selectedSection === "references-section" ? 700 : 400, // Dynamic font weight
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={12}>
                                        <FormControlLabel
                                            value="workExperiences-section"
                                            control={<Radio sx={{
                                                color: "var(--darkPurple)",
                                                '&.Mui-checked': {
                                                    color: "var(--darkPurple)",
                                                }}} />}
                                            label={wordsWithLang.cvApp.cvAppForms.template.workExperiencesSection}
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontWeight: selectedSection === "workExperiences-section" ? 700 : 400, // Dynamic font weight
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={12}>
                                        <FormControlLabel
                                            value="notes-section"
                                            control={<Radio sx={{
                                                color: "var(--darkPurple)",
                                                '&.Mui-checked': {
                                                    color: "var(--darkPurple)",
                                                }}} />}
                                            label={wordsWithLang.cvApp.cvAppForms.template.notesSection}
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontWeight: selectedSection === "notes-section" ? 700 : 400, // Dynamic font weight
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>

                        <Button variant={"contained"} color="info"
                                style={{marginLeft: 0, marginRight:20, marginBottom: 20, minWidth: "20%", borderRadius: "50px", backgroundColor:"var(--darkPurple)"}}
                                onClick={() => {
                                    imageMarginChangeRequest("bottom")
                                }}>{wordsWithLang.cvApp.cvAppForms.template.up} <ArrowUpward/></Button>
                        <Button variant={"contained"} color="info"
                                style={{ minWidth: "20%", borderRadius: "50px", marginBottom: 20, backgroundColor:"var(--darkPurple)"}}
                                onClick={() => {
                                    imageMarginChangeRequest("top")
                                }}>{wordsWithLang.cvApp.cvAppForms.template.down} <ArrowDownward/></Button>

                    </Grid>
                    <Grid item xs={12} md={9} style={{height:"700px", overflow:"scroll"}}>
                        <Grid container justifyContent={"center"} style={{backgroundColor: "gray",display:marginWait ? "none" : "flex"}}>
                            <Typography display={"block"} variant={"caption"} color={"white"}
                                        style={{marginTop: 5, marginLeft: 5}}>{"* " + wordsWithLang.cvApp.cvAppForms.template.descUse}</Typography>
                            {/*<Typography display={"block"} variant={"caption"} color={"white"}*/}
                            {/*            style={{marginTop: 5}}>{"* " + wordsWithLang.cvApp.cvAppForms.template.descUse1}</Typography>*/}
                            {newCvOrder.previewUrl && (
                                Array.from({length: newCvOrder.pagesCount}).map((_, page) => (
                                    <Box component="img"
                                         ref={previewRef}
                                        // src={newCvOrder.previewUrl}
                                         key={page}
                                         src={marginWait ? Math.random() : apiUrls.baseApiUrl + apiUrls.cvAppGetPreviewOfCv.replace("{CvOrderId}", newCvOrder.id).replace("{page}", page) + "?t=" + Date.now().toString().substring(5)}
                                         sx={{
                                             margin: 14,
                                             marginTop:2,
                                             marginBottom:2,
                                             // width: 595 ,
                                             position: "relative",
                                             width: "90%",
                                             objectFit: "contain",
                                             objectPosition: "top",
                                             backgroundColor: "white",
                                             // height: 842 ,
                                             height: "90%",
                                             backgroundSize: "contain",
                                             backgroundRepeat: "no-repeat",
                                             backgroundPosition: "top",
                                             boxShadow: "0 6px 10px rgba(0,0,0,.4), 0 0 6px rgba(0,0,0,.05)",
                                             // backgroundImage: "url('"  +"https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/81798934333513.56ccd0ec8f962.png"+ "')",
                                             display: "flex",
                                             justifyContent: "center",
                                             alignItems: "flex-end",
                                         }}

                                    />
                                )))}
                            {newCvOrder.previewUrl === null && (
                                <Typography m={5} color={"error"} fontSize={20} sx={{
                                    animation: "fadeIn 2s linear infinite",
                                    animationDirection: "alternate-reverse"
                                }}>
                                    {wordsWithLang.cvApp.cvAppForms.noPreview}
                                </Typography>
                            )}
                        </Grid>

                        <Grid container justifyContent={"center"} style={{padding: '200px', display:marginWait ? "flex" : "none"}}>
                            <Typography display={"block"}>{wordsWithLang.general.pleaseWait}</Typography>

                            <Box sx={{ width: '100%' }}>
                                <LinearProgress color={"violet"} />
                            </Box>
                        </Grid>

                    </Grid>
                </Grid>


            </DialogContent>

            <DialogActions style={{borderTop: "1px solid #000000", borderColor:"#d4d4d4"}}>
                <Button
                    variant={"outlined"}
                    // className={""}
                    color={"secondary"}
                    style={{margin: 5, borderRadius: "50px"}}
                    onClick={() => {
                        setMarginDialogOpen(false);
                        axios.get(apiUrls.baseApiUrl + apiUrls.cvAppConfirmMargins.replace("{CvOrderId}", newCvOrder.id), {withCredentials: true})
                    }}><Close fontSize="small"/>{wordsWithLang.loginDialog.closeButton}</Button>
            </DialogActions>
            {/*</>*/}
            {/*// ) : (*/}
            {/*//     <Grid container justifyContent={"center"} style={{padding: '200px'}}>*/}
            {/*//         <CircularProgress*/}
            {/*//             size={40}*/}
            {/*//             color="warning"*/}
            {/*//             thickness={4}*/}
            {/*//             variant="indeterminate"*/}
            {/*//             value={75}*/}
            {/*//             style={{margin: '30px'}}*/}
            {/*//         />*/}
            {/*//         <Typography display={"block"}>{wordsWithLang.general.pleaseWait}</Typography>*/}
            {/*//     </Grid>)}*/}
        </Dialog>
    );

    const style = {

    }
};

export default CvAppPreviewDetailedViewDialog;
